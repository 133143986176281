<script lang="ts" setup>
import HeaderNavigator from "@components/header-navigator";
import SearchInput from "@components/search-input-a/search-input-a.vue";
import HotWords from "@components/hot-words/hot-words.vue";
import FooterIndex from "@components/footer-index/footer-index.vue";
import DesignerCarousel from "@components/home/designer-carousel/designer-carousel.vue";
import Banner from "@components/banner/banner.vue";
import { useStorePageIndex } from "@stores/page/useStoreIndex";
import { useStoreSearch } from "@stores/useSearch";
import { useStoreContext } from "@stores/useStoreContext";
// import DesignerBox from "@components/home/designer-box/designer-box2.vue";
import HomeBlock from "@components/home/home-block.tsx";
import {useRouter} from "vue-router";
import { searchKeyword, getSearchRoute } from "@helpers/util";
import { storeToRefs } from "pinia";
import {useStoreUserInfo} from "@stores/useStoreUserInfo.ts";
import {showLoginModel} from "@helpers/ui.ts";
import { DEFAULT_TDK } from "@constants/configs";
import {buyVip} from "@helpers/utils";
import {statGeneral} from "@helpers/stats.ts";

defineOptions({
  name: 'PageIndex',
  asyncData: async ( options )=>{
    // const { store, route, ctx } = options;
    const context = useStoreContext();
    try {
      const { FETCH_HOT_WORD_LIST } = useStoreSearch();
      const { FETCH_INDEX_MODULES } = useStorePageIndex();
      const tasks = [
          FETCH_INDEX_MODULES({type:1}),
          FETCH_HOT_WORD_LIST({type:1})
      ]
      const res = await Promise.all( tasks );
      res.forEach( ( item, index ) => {
         if ( item[0] ) {
           context.addDebug (`PageIndexAsyncDataError:${index}`, item[0] );
         }
      });
      // 设置TDK
      context.setTitle( DEFAULT_TDK.t );
      context.setDescription( DEFAULT_TDK.d );
      context.setKeywords( DEFAULT_TDK.k );
      // console.log( res );
    } catch ( e ) {
      context.addDebug (`PageIndexAsyncDataError`, e);
    }
  }
});
const {hotWords} = useStoreSearch();
const { isUserReady } = storeToRefs ( useStoreUserInfo() );
const data = reactive({
  searchValue: ''
})
const router = useRouter();
const { statePageIndex } = useStorePageIndex ();

//#region methods
const handleSearch = ( kw ) => {
  searchKeyword ( kw, router, { newTab: true});
}
const handleClickHotWord = ( e ) => {
  const { index, item } = e;
  const kw = item.search_word;
  statGeneral({
    action_type: `home_hotword`,
    remark: `${index+1}_${kw}`
  })
  handleSearch (kw);
}
const handleLink = ( kw:string ) => {
  const route = getSearchRoute( kw );
  const config = router.resolve(route);
  const fullpath = config.fullPath;
  return fullpath;
}
const handleBuyVip = () => {
  if ( isUserReady.value ) {
    buyVip({fromText: `首页-Become VIP`});
  } else {
    showLoginModel( );
  }
}
//#endregion
</script>
<template>
    <div :class="[$style.pageContent, $style.pageIndex]">
        <banner></banner>
        <!-- 顶部导航栏 -->
        <header-navigator/>
        <!-- 顶部搜索模块 -->
        <div :class="$style[`top-bk`]">
            <div :class="$style.center">
              <h1 :class="[$style.h1, 'font-bold']">Unlimited Clip Art, Free to Download</h1>
              <p :class="$style.desc">Discover the latest trends with our 100% risk free images</p>
              <div :class="$style[`search-box`]">
                <search-input
                    :class="$style[`search-input`]"
                    v-model="data.searchValue"
                    @search="handleSearch"
                    placeholder="Search for free images"
                />
                <!-- 热门搜索词 -->
                <hot-words :class="$style[`hot-words`]"
                           :hot-words="hotWords"
                           :link="handleLink"
                           @click="handleClickHotWord"
                ></hot-words>
              </div>
            </div>
        </div>
        <!-- 顶部搜索模块结束 -->
        <div :class="$style[`main`]">
          <!-- 首页配置模块 -->
          <home-block :modules="statePageIndex.modules"/>
          <!-- banner Contributors around the world -->
          <div :class="$style[`banner-a`]">
            <div :class="$style[`bg`]"> </div>
            <div :class="$style[`left`]">
              <h3 :class="[$style[`h3`], `font-bold`]">Join millions of designers worldwide</h3>
              <p :class="$style[`p3`]">Join the VIP program with a risk-free trial</p>
<!--              <h2 :class="[$style[`h2`], `font-bold`]">100,000+<br/>contributors</h2>-->
              <button :class="[$style[`btn-join`]]" @click="handleBuyVip">
                <span :class="$style[`btn-con`]">Become VIP &gt;</span>
              </button>
            </div>
            <div :class="$style[`right`]"></div>
          </div>
          <!-- banner Contributors around the world 结束 -->
          <!-- 设计师评论 -->
          <designer-carousel :class="$style[`designer-box`]"></designer-carousel>
<!--          <designer-box :class="$style[`designer-box`]"/>-->
          <!-- 设计师评论结束 -->
          <!-- 人数 -->
          <div :class="$style[`person-num`]">
            <h2 :class="[$style[`h2`],`font-bold`]">Free download of commercially available <br/>PNG images</h2>
            <div :class="$style[`list`]">

              <div :class="$style[`li`]">
                <div :class="$style[`con`]">
                  <div :class="$style[`l`]">
                    <div :class="$style[`icon-avatar`]">
                      <img :src="`${process.env.CDN_CP_PATH}/img/icon/pserson.svg`" alt="icon-avatar"/>
                    </div>
                  </div>
                  <div :class="$style[`r`]">
                    <p :class="[$style[`num`], `font-medium`]">24,719</p>
                    <span :class="$style[`desc`]">Registered users</span>
                  </div>
                </div>
              </div>

              <div :class="$style[`li`]">
                <div :class="$style[`con`]">
                  <div :class="$style[`l`]">
                    <div :class="$style[`icon-avatar`]">
                      <img :src="`${process.env.CDN_CP_PATH}/img/icon/downloads.svg`" alt="icon-download"/>
                    </div>
                  </div>
                  <div :class="$style[`r`]">
                    <p :class="[$style[`num`], `font-medium`]">3,142,519</p>
                    <span :class="$style[`desc`]">Total downloads</span>
                  </div>
                </div>
              </div>

              <div :class="$style[`li`]">
                <div :class="$style[`con`]">
                  <div :class="$style[`l`]">
                    <div :class="$style[`icon-avatar`]">
                      <img :src="`${process.env.CDN_CP_PATH}/img/icon/updates.svg`" alt="icon-updates"/>
                    </div>
                  </div>
                  <div :class="$style[`r`]">
                    <p :class="[$style[`num`], `font-medium`]">10,000+</p>
                    <span :class="$style[`desc`]">New updates</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- 的人数结束 -->
        </div>
        <footer-index :class="$style[`footer-index`]"/>
    </div>
</template>
<style lang="scss" module src="./index.scss"></style>

<template>
  <div :class="$style[`home-activities`]">
    <div :class="$style[`title`]" v-if="title">
      <h2 >{{title}}</h2>
    </div>
    <div ref="wrapper" :class="$style.activityWrapper">
      <transition name="fade">
        <div v-show="showRight"
             :class="$style.toRight"
             @click="toRight" />
      </transition>
      <transition name="fade">
        <div v-show="showLeft"
             :class="$style.toLeft"
             @click="toLeft" />
      </transition>

      <ul ref="con" :class="$style.activityCon" :style="conStyle">
        <li :class="$style.activity"
            v-for="(item,index) in showList" :key="index"
            @click.prevent="toActivity(item,index)"
        >
          <!-- 带链接的活动 -->
          <gc-auto-link
             :link="item.jump_link"
             :check-login="true"
             :is-login="isUserReady"
             :class="$style.activityItem"
             @login="handleLogin"
          >
            <img :src="item.img_url" :alt="item.content_name">
          </gc-auto-link>
        </li>
      </ul>
    </div>
    <div :class="[$style.banner, $style.activityWrapper]" v-for="(banner, bannerIndex) in banners">
      <gc-auto-link
          :link="banner.jump_link"
          :class="$style.bannerCon"
          :check-login="true"
          :is-login="isUserReady"
          @login="handleLogin"
          @click="handleBannerClick(bannerIndex)"
      >
        <img :src="banner.img_url" :alt="banner.content_name"/>
      </gc-auto-link>
    </div>
  </div>
</template>
<script setup lang="ts">
import TWEEN from "@tweenjs/tween.js";
import {computed, ref, onMounted, onActivated, onDeactivated } from "vue";
import {useStoreUserInfo} from "@stores/useStoreUserInfo";
import {storeToRefs} from "pinia";
import {showLoginModel} from "@helpers/ui.ts";
import {statGeneral} from "@helpers/stats.ts";
const props = defineProps<{
  activitesArr: any[]; // 活动列表
  title: string; // 标题
}>();

const storeUserInfo = useStoreUserInfo();
const { isUserReady } = storeToRefs(storeUserInfo);

// 活动横向滑动距离
const scrollX = ref<number>(0);
// 是否显示向右按钮
const showRight = ref<boolean>(false);
// 是否显示向左按钮
const showLeft = ref<boolean>(false);
// 是否不能点击滚动
let freeze = false;
// 页面是否处于活动状态
let pageActive = false;
// 取消动画
let isAnimate = false;

// refs
const wrapper = ref(null);
const con = ref(null);

// 跳转到活动
const toActivity = ( e , index:number ) => {
  statGeneral({
    action_type: `home_item`,
    remark: `${props.title}_1_${index+1}`
  })
}
const handleBannerClick = ( index ) => {
  statGeneral({
    action_type: `home_item`,
    remark: `${props.title}_1_${showList.value.length+index+1}`
  })
}
/************* lifecycle start *************/
onMounted(()=>{

})
onActivated(()=>{
  pageActive = true;
  handleResize();
  // requestAnimationFrame(animate);
  window.addEventListener('resize',handleResize)
})
onDeactivated(()=>{
  pageActive = false;
  window.removeEventListener('resize',handleResize)
})

/************* lifecycle end *************/

/************* methods start *************/
const animationResize = () => {
  // @ts-ignore
  const wrapperW = con.value.getBoundingClientRect().width;
  // @ts-ignore
  const conW = con.value.scrollWidth;
  // console.log( "handleResize", wrapperW, conW)
  if(conW > wrapperW ){
    if( Math.abs(scrollX.value) < 12 ){
      showRight.value = true;
      showLeft.value = false;
    }else{
      showRight.value = false;
      showLeft.value = true;
    }
  }else{
    showRight.value = false;
    showRight.value = false;
    scrollX.value = 0;
  }
}
const handleResize = ()=>{
  // @ts-ignore
  con.value.style.transform = ` translate3d(0, 0, 1px)`;
  scrollX.value = 0;
  // @ts-ignore
  const wrapperW = con.value.getBoundingClientRect().width;
  // @ts-ignore
  const conW = con.value.scrollWidth;
  // console.log( "handleResize", wrapperW, conW)
  if(conW > wrapperW ){
    if( Math.abs(scrollX.value) < 12 ){
      showRight.value = true;
      showLeft.value = false;
    }else{
      showRight.value = false;
      showLeft.value = true;
    }
  }else{
    showRight.value = false;
    showRight.value = false;
    scrollX.value = 0;
  }
};
const animate = (time: number) => {
  if(!pageActive || !isAnimate ) return;
  requestAnimationFrame(animate);
  TWEEN.update(time);
};

// 横向滚动
const scrollAni = ( start:number, end:number ) => {
  if(freeze) return;
  freeze = true;
  isAnimate = true;
  requestAnimationFrame(animate);
  const transform = {
    left: start
  }
  new TWEEN.Tween(transform)
      .to({ left:end }, 1000)
      .easing(TWEEN.Easing.Quartic.InOut)
      .onUpdate( ( options )=>{
        scrollX.value = options.left
        if(options.left === end){
          // handleResize();
          animationResize();
          freeze = false;
          isAnimate = false;
        }
      }).start()
};
// 向右滑动
const toRight = ()=>{
  // @ts-ignore
  const wrapperW = con.value.getBoundingClientRect().width;
  // @ts-ignore
  const conW = con.value.scrollWidth;
  scrollAni(0,wrapperW-conW)
};
// 向左滑动
const toLeft = ()=>{
  scrollAni(scrollX.value,0)
};

const handleLogin = () => {
  showLoginModel();
}
/************* methods end *************/

/************* computed start *************/
    // 活动列表
// const activitesArr = computed(()=>store.state.operationsCommon.home_s);
const conStyle = computed(()=>{
  return { transform: `translate3d(${scrollX.value}px,0, 1px)`}
})
/** 活动列表 */
const showList = computed(()=>{
  return props.activitesArr.filter( item => item.type === 1 )
})
/** banner列表 */
const banners = computed(()=>{
  return props.activitesArr.filter( item => item.type === 2 )
})
/************* computed end *************/
</script>
<style lang="scss" module src="./home-activities.scss"></style>
import type { TIndexModule } from "@stores/page/useStoreIndex";
import HomeActivities from "@components/home/home-activities/home-activities.vue";
import HomeGrids from "@components/home/home-grids/home-grids.vue";
import styles from './home-block.module.scss';
export type HomeBlockProps = {
    modules: TIndexModule[];
}
export default function HomeBlock( props:HomeBlockProps ) {
    const blocks = props.modules.map ( ( module, index ) => {
       const hasContent = module.content.length;
       const isAd = hasContent && module.content[0].type === 1 ;
       if ( isAd ) {
           return <HomeActivities class={[styles.homeBk, `home-bk`]} activitesArr={module.content} title={module.title_name}></HomeActivities>
       } else {
           return <HomeGrids  class={[styles.homeBk, `home-bk`]} module={module} title={module.title_name}></HomeGrids>
       }
    } )
  return (
    <div>
        {
            blocks
        }
    </div>
  );
}
<template>
  <div :class="[$style.item, $style[`item-${item.type}`]]">
    <gc-auto-link :class="$style[`link`]"
        :link="props.item.jump_link"
        :check-login="true"
        :is-login="isUserReady"
        @login="handleLogin"
        @click="handleClick"
    >
      <!-- 产看更多 -->
      <div v-if="props.item.showMore" :class="[$style['more-overlay'], `more-overlay`]">
        <a :class="[$style[`more`] ]"
           :href="link"
           target="_blank"
           @click.prevent
        >See more &gt;</a>
      </div>
      <div :class="$style[`img-overlay`]"></div>
      <img :class="$style[`img`]" :src="props.item.img_url" @load="handleImgLoaded" :alt="props.item.content_name" @contextmenu.prevent/>
    </gc-auto-link>
    <!-- 功能按钮 -->
    <div v-if="!props.item.showMore" :class="[$style['overlay'], 'overlay']">
      <div :class="[$style[`btn`],$style[`download`] ]" @click.stop="toDownload">
        <dItemLoading v-if="isLoading" size="small"></dItemLoading>
        <inline-svg v-else :src="gridItemDown"></inline-svg>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type {IIndexModulesContent} from "global-common/apis/img-clippng/home/block/content/list.ts";
import { useStoreUserInfo } from "@stores/useStoreUserInfo"
import {showLoginModel} from "@helpers/ui";
import {download} from "@helpers/downLoad";
import {useStoreDownload} from "@stores/useStoreDownload";
import gridItemDown from "@images/grid-item-down.svg";
import {statGeneral} from "@helpers/stats.ts";
const {subtractionDownloadCount} = useStoreDownload();
const props = defineProps({
  item: {
    type: Object as PropType<IIndexModulesContent>,
    required: true
  }
});
const emits = defineEmits([`click`, `download`]);
const isImgLoaded = ref(false);
const storeUserInfo = useStoreUserInfo();
const { isUserReady } = storeToRefs(storeUserInfo);
const isLoading = ref ( false );
/* ************** Methods ************** */
const handleImgLoaded = () => {
  isImgLoaded.value = true;
};
const handleClick = () => {
  emits(`click` );
}
const handleLogin = ( e ) => {
  console.log ( `handleLogin`, e );
  showLoginModel()
}

/** 下载 */
const toDownload = async () => {
  const { getToken } = useStoreUserInfo();
  const token = getToken();
  const { isUserReady } = storeToRefs(useStoreUserInfo());
  emits(`download`, { item: props.item})
  if (!isUserReady.value) {
    showLoginModel();
    return;
  }
  const id = props.item.img_id;
  if(!id) return;
  isLoading.value = true
  console.log(id, 'id');
  await download({
    img_id: Number(id),
    token,
    start:() => {
      isLoading.value = false;
    },
    beforeDownload: async () => {
      console.log(`123123`)
    } });
  subtractionDownloadCount(Number(id))
}

/* ************** computed ************** */
const link = computed(()=>{
  return props.item.jump_link;
})
/* ************** watch ************** */
watch(() => props.item.img_url, ( newVal, oldVal ) => {
  if (newVal !== oldVal) {
    isImgLoaded.value = false;
  }
});
</script>
<style lang="scss" module src="./home-grid-item.scss"></style>

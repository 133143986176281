import { defineStore } from "pinia";
import { reactive } from "vue";
import fetchHomeTitleList, {TTitleList, IIndexModule } from "global-common/apis/img-clippng/home/title/list.ts";
import fetchHomeBlockContentList, { THomeBlockContentList, IIndexModulesContent } from "global-common/apis/img-clippng/home/block/content/list.ts"
export type TIndexModule = {
    content?: IIndexModulesContent[]
} & IIndexModule;
export type TStateIndex = {
    modules: Array<TIndexModule>
}
export const usePageIndex = () => {
    /* ******************* data ******************* */
    const statePageIndex = reactive({
        modules: [], // 首页模块列表
    } as TStateIndex ) ;

    /* ******************* Methods ******************* */

    /**
     * @description: 遍历模块id
     * @param modules
     * @param callback
     */
    const traversalModuleIds = ( modules: IIndexModule[], callback: ( e:TIndexModule)=>void ) => {
        // 获取所有模块id
        const findId = ( module: IIndexModule ) => {
            if ( module.id ) {
                callback && callback ( module )
            } ;
            if ( module.children.length ) {
                module.children.forEach( findId );
            }
        }
        modules.forEach( findId );
    }

    /* ******************* Actions ******************* */
    const FETCH_INDEX_MODULES = async ( params: TTitleList ) => {
        try {
            // 获取首页模块列表
            const [ err, res ] = await fetchHomeTitleList( params );
            if ( err ) throw err ;
            const modules = res.data as TIndexModule[];
            // console.log( `modules`, modules );
            // 获取所有模块id，并发内容请求
            const ids = [];
            traversalModuleIds ( modules, ( module ) => ids.push(module.id) );
            const tasks = ids.map ( ( id ) => {
                return fetchHomeBlockContentList({ title_id:id });
            })
            const taskRes = await Promise.all( tasks );
            let taskErr;
            // 合并模块列表
            traversalModuleIds ( modules, ( module ) => {
                const index = ids.indexOf( module.id );
                const [ err, task ] = taskRes[index];
                // console.log( `traversalModuleIds`, task );
                if ( err ) { taskErr = err }
                module.content = [...(task?.data || [])];
            } );
            statePageIndex.modules = modules;
            // console.log( `modules-result`, modules );
            if ( taskErr ) {
                return [ taskErr, modules ];
            } else {
                return [ null, modules ];
            }
        } catch ( e ) {
            return [ e, null ];
        }
    }

    /* ******************* return ******************* */
    return {
        FETCH_INDEX_MODULES,
        statePageIndex
    }
}
/**
 * @description: 首页store
 */
export const useStorePageIndex = defineStore("PageIndex", usePageIndex );

import {$axios, GcResponse} from "../../../helper";
import {ServerClippngImg} from "../../../../lib/constants/server";

export type TTitleList = {
    type: number,
}
// 首页模块列表
export type IIndexModule = {
    id: number,
    parent_id: number,
    title_name: string,
    children: IIndexModule[],
}

/** 拿首页块的标题
 * @see http://doc.bigbigwork.com/docs/hw/7939
 */
export default function fetchHomeTitleList (
    params: TTitleList
) {
    return $axios<GcResponse<IIndexModule[]>> ({
        url: `${ServerClippngImg}/home/title/list`,
        method: "GET",
        params: params
    })
}
<template>
  <div :class="$style[`home-grids`]" >
    <!-- 标题 -->
    <div :class="$style[`title`]" v-if="title">
      <h2 >{{title}}</h2>
    </div>
    <!-- 分类 -->
    <div :class="[$style[`category`] ]" v-if="hasCategory">
      <div :class="[$style[`category-item`], isActive(module.id) && $style.active]"
           v-for="(module, index) in props.module.children"
           :key="module.id"
           @click="handleChangeCategory( module )"
      >
        <div :class="$style[`category-item-title`]">
          <span>{{module.title_name}}</span>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div :class="[$style[`content-bk`], $style[`content-bk-${displayType}`], contents.length <= 5 && $style[`one-line`]]" >
      <div :class="[$style[`grid-item`], $style[`grid-item-${index+1}`], $style[`type-${item.type}`]]"
           v-for="(item, index) in contents"
      >
        <home-grid-item :item="item"
                        @click="handleItemClick(module.title_name, currentModuleIndex, index)"
                        @download="handleDownload(module.title_name, currentModuleIndex, index, item.id )"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type {TIndexModule} from "@stores/page/useStoreIndex.ts";
import HomeGridItem from "@components/home/home-grid-item/home-grid-item.vue";
import {statGeneral} from "@helpers/stats.ts";
const props = defineProps<{
  module: TIndexModule; // 模块列表
  title: string; // 标题
}>();

/* ****************************** 变量 ****************************** */
// 当前激活的模块id
const activeModuleId = ref<number>(0);

/* ****************************** 生命周期 ****************************** */
onBeforeMount(() => {
  if (hasCategory.value) {
    activeModuleId.value = props.module.children[0].id;
  }
});
onActivated(() => {
  handleResize();
  window.addEventListener( 'resize', handleResize );
});
onDeactivated(() => {
  window.removeEventListener( 'resize', handleResize );
});
/* ****************************** 方法 ****************************** */
// 点击分类下单个item
const handleItemClick = ( title, index1, index2 ) => {
  // http://doc.bigbigwork.com/docs/hwsc/8023
  statGeneral({
    action_type: `home_item`,
    remark: `${title}_${index1+1}_${index2+1}`
  })
}
// 点击分类下单个item的下载
const handleDownload = ( title, index1, index2, id ) => {
  // http://doc.bigbigwork.com/docs/hwsc/8023
  statGeneral({
    action_type: `home_dl`,
    remark: `${title}_${index1+1}_${index2+1}_${id}`
  })
}
// 切换分类
const handleChangeCategory = (module: TIndexModule) => {
  activeModuleId.value = module.id;
  handleResize();
  statGeneral({
    action_type: `home_block`,
    remark: `${currentModuleIndex}_${module.title_name}`
  })
};
// 窗口大小改变, 最后一个元素显示更多
const handleResize = ( ) => {
  const width = window.innerWidth;
  let last = 10;
  if ( displayType.value === 1 ) {
    const size = [
      { w: 0, num: 8},
      { w: 1200, num: 8},
      { w: 1480, num: 10}
    ]
    if ( width < size[2].w ) {
      last = 8;
    }
  } else {
    const size = [
      { w: 0, num: 9},
      { w: 1200, num: 9},
      { w: 1480, num: 10}
    ]
    if ( width < size[2].w ) {
      last = 9;
    }
  }
  for ( let i = 0; i< contents.value.length; i++ ) {
    const item = contents.value[i];
    if ( ( i >= last ) || ( i >= contents.value.length - 1 )) {
      item.showMore = true;
    } else {
      item.showMore = false;
    }
  }
}
/* ****************************** 计算属性 ****************************** */
// 是否激活
const isActive = computed(() => (id: number) => activeModuleId.value === id);
// 是否有分类
const hasCategory = computed(() => props.module.children.length > 0);
// 当前展示的模块
const currentModule = computed(() => {
  if (hasCategory.value) {
    return props.module.children.find((module) => module.id === activeModuleId.value) as TIndexModule || props.module.children[0];
  } else {
    return props.module as TIndexModule;
  }
}) ;
// 当前展示模块的index
const currentModuleIndex = computed(()=>{
  const index = props.module.children.findIndex((item)=> item.id === activeModuleId.value);
  return index > -1 ? index : 0;
})

/** 布局样式
 * 1: 1长方布局, 2: 全是方块图
 */
const displayType = computed(() => {
  // 有一个type=3的就是1大布局
  return currentModule.value?.content?.some((item) => item.type === 3) ? 1 : 2;
});
/**
 * 显示的内容
 */
const contents = computed(() => {
  return currentModule?.value?.content || [];
});
</script>
<style lang="scss" module src="./home-grids.scss"></style>
import {$axios, GcResponse} from "../../../../helper";
import {ServerClippngImg} from "../../../../../lib/constants/server";

export type THomeBlockContentList = {
    title_id: number,
}
export type IIndexModulesContent = {
    id: number,
    content_name: string,
    img_url: string,
    jump_link: string,
    type: number,
    [name:string]:any
}
/** 根据标题拿内容
 * @see http://doc.bigbigwork.com/docs/hw/7941
 */
export default function fetchHomeBlockContentList (
    params: THomeBlockContentList
) {
    return $axios<GcResponse<IIndexModulesContent[]>> ({
        url: `${ServerClippngImg}/home/block/content/list`,
        method: "GET",
        params: params
    })
}